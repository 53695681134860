import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles, Grid, Box, Avatar } from "@material-ui/core";
import { Layout, main } from "helpers/MainLayout";
import MainContainer from "helpers/MainContainer";
import { Headline, Subheadline, Caption } from "helpers/text";
import { ExternalLink } from "helpers/Link";
import { makeText } from "helpers/i18n";

// ----------------------------------------------------------------
//     テキスト
// ----------------------------------------------------------------

const useText = makeText({
  ja: {
    title: "スタッフ一覧",
    description: "DroidKaigiの運営スタッフ一覧です。",
  },
  en: {
    title: "Committee members",
    description: "List of DroidKaigi operation staff.",
  },
});

// ----------------------------------------------------------------
//     各種コンポーネント
// ----------------------------------------------------------------

const useStaffIconStyle = makeStyles((theme) => ({
  root: {
    display: "block",
    textDecoration: "none",
    textAlign: "center",
    wordWrap: "break-word",
    color: theme.palette.text.primary,
  },
  icon: {
    [theme.breakpoints.down("xs")]: {
      width: "60px",
      height: "60px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "80px",
      height: "80px",
    },
    margin: "auto",
  },
}));

const StaffIcon: React.FC<{
  name: string;
  profile: string;
  icon: string;
}> = ({ name, profile, icon }) => {
  const classNames = useStaffIconStyle();
  return (
    <Grid item xs={3} sm={2}>
      <Box>
        <ExternalLink className={`${classNames.root}`} href={`${profile}`}>
          <Avatar className={`${classNames.icon}`} src={`${icon}`} />
          <Caption>{name}</Caption>
        </ExternalLink>
      </Box>
    </Grid>
  );
};

const useStaffIconWrapperStyle = makeStyles(() => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
}));

const StaffIconWrapper: React.FC<{
  staffList: Info[];
}> = ({ staffList }) => {
  const classNames = useStaffIconWrapperStyle();
  return (
    <Box className={classNames.root} mt={4} mx={2}>
      <Grid container spacing={2}>
        {staffList.map((data, i) => (
          <StaffIcon
            key={i}
            name={data.name}
            profile={data.profile}
            icon={data.icon}
          ></StaffIcon>
        ))}
      </Grid>
    </Box>
  );
};

// ----------------------------------------------------------------
//     全体構成
// ----------------------------------------------------------------

export const Staffs: React.FC = () => {
  const text = useText();
  const allCommitteeMembers = useCommitteeMembers();

  return (
    <Layout title={text.title}>
      <MainContainer>
        <Box mt={{ xs: 5, sm: 10 }} mb={{ xs: 5, sm: 10 }}>
          <Headline>{text.title}</Headline>
          <Subheadline color="textSecondary">{text.description}</Subheadline>
          <StaffIconWrapper staffList={allCommitteeMembers}></StaffIconWrapper>
        </Box>
      </MainContainer>
    </Layout>
  );
};

export default main(Staffs);

// ----------------------------------------------------------------
//     データ取得
// ----------------------------------------------------------------

interface Info {
  name: string;
  profile: string;
  icon: string;
}

const useCommitteeMembers = (): Info[] => {
  const {
    allCommitteeMembers: { data },
  } = useStaticQuery(graphql`
    {
      allCommitteeMembers {
        data {
          name
          profileUrl
          icon128Url
        }
      }
    }
  `);

  /* eslint-disable @typescript-eslint/no-explicit-any */
  return data.map((s: any) => ({
    name: s.name,
    profile: s.profileUrl,
    icon: s.icon128Url,
  }));
  /* eslint-enable */
};
